import React, { useEffect, useState } from "react";
import { FaFileAlt } from "react-icons/fa";
import { IoPeopleSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { TbMessage2Check } from "react-icons/tb";
import { MdEmail } from "react-icons/md";
import { FaCheckDouble } from "react-icons/fa6";
import { RiDashboardHorizontalFill } from "react-icons/ri";
import { BsInfoCircleFill } from "react-icons/bs";

function Sidenav() {
  const name = useSelector((state) => state.GlobalState.userDetails.name);

  const [path, setPath] = useState(null);
  const location = useLocation();

  const [hoverPath, setHoverPath] = useState(null);

  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);

  return (
    <div
      style={{ width: window.innerWidth / 5 }}
      className="px-8 h-screen py-10 flex flex-col items-center justify-between fixed top-0 left-0 border-r-2 border-secondary"
    >
      <div className="flex flex-col gap-8 w-full">
        <p className="rounded-full bg-secondary px-3 py-2 text-center w-full text-white font-semibold">{`Hi, ${name}`}</p>
        <div className="text-secondary flex flex-col gap-5">
          <NavLink
            to="/dashboard"
            className={`px-2 py-1 rounded-md flex gap-2 items-center ${
              path === "/dashboard" && "bg-gray-200"
            } hover:bg-gray-100 relative`}
          >
            <RiDashboardHorizontalFill />
            <p>Dashboard</p>
          </NavLink>
          <NavLink
            to="/my-cv"
            onMouseEnter={() => setHoverPath("/my-cv")}
            onMouseLeave={() => setHoverPath(null)}
            className={`px-2 py-1 rounded-md flex gap-2 items-center ${
              path === "/my-cv" && "bg-gray-200"
            } hover:bg-gray-100 relative`}
          >
            {hoverPath === "/my-cv" && (
              <div className={`absolute right-2 my-auto  `}>
                <div className="relative group">
                  <BsInfoCircleFill />
                  <div className="hidden group-hover:block hover:block absolute -left-2 top-[50%] -translate-y-[50%] aspect-video w-[400px] rounded-xl primary-shadow bg-white p-3  z-[100000] transition-all duration-500">
                    <iframe
                      src="https://www.youtube.com/embed/wCcr6PAY-0E?si=DQkC2oonkJsWgvBz"
                      title="YouTube video player"
                      className="w-full aspect-video"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </div>{" "}
                </div>
              </div>
            )}{" "}
            <FaFileAlt />
            <p>My CV</p>
          </NavLink>
          <NavLink
            to="/interview-room"
            onMouseEnter={() => setHoverPath("/interview-room")}
            onMouseLeave={() => setHoverPath(null)}
            className={`px-2 py-1 rounded-md flex gap-2 items-center ${
              path === "/interview-room" && "bg-gray-200"
            } hover:bg-gray-100 relative`}
          >
            {hoverPath === "/interview-room" && (
              <div className={`absolute right-2 my-auto  `}>
                <div className="relative group">
                  <BsInfoCircleFill />
                  <div className="hidden group-hover:block hover:block absolute -left-2 top-[50%] -translate-y-[50%] aspect-video w-[400px] rounded-xl primary-shadow bg-white p-3  z-[100000] transition-all duration-500">
                    <iframe
                      className="w-full aspect-video"
                      src="https://www.youtube.com/embed/YDzEtzsbuSY?si=O-AWuZZFZEZc7LhX"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </div>{" "}
                </div>
              </div>
            )}{" "}
            <IoPeopleSharp />
            <p>Interview room</p>
          </NavLink>
          <NavLink
            to="/cover-letter"
            onMouseEnter={() => setHoverPath("/cover-letter")}
            onMouseLeave={() => setHoverPath(null)}
            className={`px-2 py-1 rounded-md flex gap-2 items-center ${
              path === "/cover-letter" && "bg-gray-200"
            } hover:bg-gray-100 relative`}
          >
            {hoverPath === "/cover-letter" && (
              <div className={`absolute right-2 my-auto  `}>
                <div className="relative group">
                  <BsInfoCircleFill />
                  <div className="hidden group-hover:block hover:block absolute -left-2 top-[50%] -translate-y-[50%] aspect-video w-[400px] rounded-xl primary-shadow bg-white p-3  z-[100000] transition-all duration-500">
                    <iframe
                      className="w-full aspect-video"
                      src="https://www.youtube.com/embed/U5-zOxcV2q0?si=XGambBrCT6ctjUUd"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </div>{" "}
                </div>
              </div>
            )}{" "}
            <TbMessage2Check />
            <p>Cover letter</p>
          </NavLink>
          <NavLink
            to="/cold-email"
            onMouseEnter={() => setHoverPath("/cold-email")}
            onMouseLeave={() => setHoverPath(null)}
            className={`px-2 py-1 rounded-md flex gap-2 items-center ${
              path === "/cold-email" && "bg-gray-200"
            } hover:bg-gray-100 relative`}
          >
            {hoverPath === "/cold-email" && (
              <div className={`absolute right-2 my-auto  `}>
                <div className="relative group">
                  <BsInfoCircleFill />
                  <div className="hidden group-hover:block hover:block absolute -left-2 top-[50%] -translate-y-[50%] aspect-video w-[400px] rounded-xl primary-shadow bg-white p-3  z-[100000] transition-all duration-500">
                    <iframe
                      src="https://www.youtube.com/embed/3ExJcU2xswA?si=n6rxZAIxlnA--e95"
                      className="w-full aspect-video"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </div>{" "}
                </div>
              </div>
            )}{" "}
            <MdEmail />
            <p>Cold emailing</p>
          </NavLink>
          <NavLink
            to="/get-advice"
            onMouseEnter={() => setHoverPath("/get-advice")}
            onMouseLeave={() => setHoverPath(null)}
            className={`px-2 py-1 rounded-md flex gap-2 items-center ${
              path === "/get-advice" && "bg-gray-200"
            } hover:bg-gray-100 relative`}
          >
            {hoverPath === "/get-advice" && (
              <div className={`absolute right-2 my-auto  `}>
                <div className="relative group">
                  <BsInfoCircleFill />
                  <div className="hidden group-hover:block hover:block absolute -left-2 top-[50%] -translate-y-[50%] aspect-video w-[400px] rounded-xl primary-shadow bg-white p-3  z-[100000] transition-all duration-500">
                    <iframe
                      src="https://www.youtube.com/embed/QC_tXAt7gAs?si=qiuIxj77Dg_rhtIg"
                      title="YouTube video player"
                      className="w-full aspect-video"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            )}{" "}
            <FaCheckDouble />
            <p>Get advice (Beta)</p>
          </NavLink>
        </div>
      </div>

      <div className="w-full">
        <NavLink
          to={"/feedback"}
          className="block rounded-full bg-secondary px-3 py-2 text-center hover:bg-secondary/70 text-white font-semibold w-full"
        >
          Give feedback
        </NavLink>
        <button></button>
      </div>
    </div>
  );
}

export default Sidenav;
