import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import userData from "../../services/userData";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Loading } from "../../pages/InterviewRoom";

function AdminFilter() {
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const [filterEmail, setFilterEmail] = useState("");
  const [referralCodeFilter, setReferralCodeFilter] = useState("");

  const [filterCurrency, setFilterCurrency] = useState("");

  const [amountLower, setAmountLower] = useState(0);
  const [amountUpper, setAmountUpper] = useState(10000);

  const [activeUsers, setActiveUsers] = useState(false);

  const [users, setUsers] = useState(null);
  const [renderUsers, setRenderUsers] = useState(null);

  async function adminFilter() {
    try {
      setUsers(null);
      const token = Cookies.get("token");
      const data = {
        token,
        email: filterEmail,
      };
      const res = await userData.adminFilter(data);
      setUsers(res.data?.users);
      console.log(res);
    } catch (err) {
      console.log(err);
      toast.error(err.response?.data?.message || "Error filtering user");
    }
  }

  useEffect(() => {
    if (users) {
      let arr = [...users];
      if (referralCodeFilter !== "") {
        arr = arr.filter(
          (user) =>
            user.referralCode
              ?.toLowerCase()
              .includes(referralCodeFilter.toLowerCase()) &&
            user.price >= amountLower &&
            user.price <= amountUpper
        );
      }

      if (filterCurrency !== "") {
        arr = arr.filter((user) => user.currency.includes(filterCurrency));
      }

      arr = arr.filter(
        (user) => user.price >= amountLower && user.price <= amountUpper
      );

      setRenderUsers(arr);
    }
  }, [users, referralCodeFilter, amountLower, amountUpper, filterCurrency]);

  const sort_id_mapping = {
    1: "subscribedOn",
    2: "expiry",
    3: "createdAt",
  };
  function sortUtility(sorting_id) {
    const key = sort_id_mapping[sorting_id];
    const arr = [...renderUsers];
    if (renderUsers && Array.isArray(renderUsers) && renderUsers.length > 0) {
      setRenderUsers(arr.sort((a, b) => new Date(a[key]) - new Date(b[key])));
    }
  }

  const [open, setOpen] = useState(false);
  const [extendMonths, setExtendMonths] = useState(0);
  const [extendDays, setExtendDays] = useState(0);
  const [extendEmail, setExtendEmail] = useState("");

  async function manualAdjustPlan() {
    try {
      const token = Cookies.get("token");
      const data = {
        token,
        email: extendEmail,
        months: extendMonths,
        days: extendDays,
      };
      const res = await userData.manualAdjustPlan(data);
      if (res.data?.success) {
        toast.success(res.data?.message || "Updated successfully");
        adminFilter();
        setExtendMonths(0);
        setExtendDays(0);
        setExtendEmail("");
      }
      console.log(res);
    } catch (err) {
      console.log(err);
      toast.error(err.response?.data?.message || "Error extending plan");
    } finally {
      setOpen(false);
    }
  }

  useEffect(() => {
    adminFilter();
  }, []);

  return (
    <div className="my-8 pb-[40vh]">
      <div className="flex justify-around items-center my-5">
        <div className="flex gap-2 items-center">
          <TextField
            label="Enter email to filter"
            value={filterEmail}
            onChange={(e) => setFilterEmail(e.target.value)}
            variant="outlined"
            className="mb-4"
          />
          <Button
            onClick={adminFilter}
            variant="contained"
            color="primary"
            className="mb-4"
          >
            Filter
          </Button>
        </div>

        <TextField
          label="Filter by coupon"
          value={referralCodeFilter}
          onChange={(e) => setReferralCodeFilter(e.target.value)}
          variant="outlined"
          className="mb-4"
        />
        <TextField
          type="range"
          label={`Price lower limit: ${amountLower}`}
          value={amountLower}
          onChange={(e) => setAmountLower(e.target.value)}
          inputProps={{ min: 0, max: 10000, step: 10 }}
          variant="outlined"
          className="mb-4"
        />
        <TextField
          type="range"
          label={`Price upper limit: ${amountUpper}`}
          value={amountUpper}
          onChange={(e) => setAmountUpper(e.target.value)}
          inputProps={{ min: 0, max: 10000, step: 10 }}
          variant="outlined"
          className="mb-4"
        />
      </div>
      <div>
        <div className="flex gap-2 items-center">
          <Checkbox
            checked={activeUsers}
            onChange={(e) => {
              setActiveUsers(e.target.checked);
            }}
          />
          <span>Active users</span>
        </div>
        <div>
          <TextField
            label="Filter by currency"
            value={filterCurrency}
            onChange={(e) => setFilterCurrency(e.target.value)}
            variant="outlined"
            className="mb-4"
          />
        </div>
      </div>
      <div className="flex justify-evenly items-center my-5">
        <Button
          variant="outlined"
          color="success"
          onClick={() => {
            sortUtility(1);
          }}
        >
          Sort by subscription start date
        </Button>

        <Button
          variant="outlined"
          color="success"
          onClick={() => {
            sortUtility(2);
          }}
        >
          Sort by subscription end date
        </Button>

        <Button
          variant="outlined"
          color="success"
          onClick={() => {
            sortUtility(3);
          }}
        >
          Sort by registration date
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Coupon</TableCell>
              <TableCell>Coupon ID</TableCell>
              <TableCell>Coupon name</TableCell>
              <TableCell>Start date</TableCell>
              <TableCell>End date</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Paid</TableCell>
              <TableCell>Registered</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderUsers &&
              Array.isArray(renderUsers) &&
              renderUsers.length > 0 &&
              renderUsers
                .filter((user) => !activeUsers || user.subscribed)
                .map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {user.subscribed ? "Active" : "Inactive"}
                    </TableCell>
                    <TableCell>
                      <p className="whitespace-nowrap">
                        {user.subscriptionType ? user.subscriptionType : "-"}
                      </p>
                    </TableCell>
                    <TableCell>
                      {user.referralCode ? user.referralCode : "NA"}
                    </TableCell>
                    <TableCell>
                      {user.couponId ? user.couponId : "NA"}
                    </TableCell>
                    <TableCell>
                      {user.couponName ? user.couponName : "NA"}
                    </TableCell>
                    <TableCell>
                      {user.subscribed ? formatDate(user.subscribedOn) : "NA"}
                    </TableCell>
                    <TableCell>
                      {user.subscribed ? formatDate(user.expiry) : "NA"}
                    </TableCell>
                    <TableCell>{user.currency ? user.currency : "-"}</TableCell>
                    <TableCell>{user.price ? user.price : "-"}</TableCell>
                    <TableCell>{formatDate(user.createdAt)}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          setExtendEmail(user.email);
                          setOpen(true);
                        }}
                        variant="outlined"
                        color="secondary"
                      >
                        Extend plan
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            {users && Array.isArray(users) && users.length === 0 && (
              <p>No users found</p>
            )}
            {!users && <Loading></Loading>}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Manually adjusting plan, be careful !!!</DialogTitle>
        <DialogContent>
          <p className="my-5">Adjusting plan for: {extendEmail}</p>
          <TextField
            type="number"
            label="Enter months"
            value={extendMonths}
            onChange={(e) => setExtendMonths(e.target.value)}
            variant="outlined"
            className="mb-4"
          />
          <TextField
            type="number"
            label="Enter days"
            value={extendDays}
            onChange={(e) => setExtendDays(e.target.value)}
            variant="outlined"
            className="mb-4"
          />
          <Button
            onClick={manualAdjustPlan}
            variant="contained"
            color="primary"
          >
            Adjust
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AdminFilter;
