import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import SpiderMap from "./SpiderMap";

function Heatmap() {
  const [score, setScore] = useState(
    JSON.parse(localStorage.getItem("matrix_score"))
  );

  const [mode, setMode] = useState(0);

  const mapRef = useRef(null);

  const styles = {
    tooltip: {},
  };

  function generateHeatmap(container) {
    console.log("Generating Heatmap", container);
    const data = [
      { row: "Quantity", col: "Qualification", value: score[3] },
      { row: "Quantity", col: "Skills", value: score[0] },
      { row: "Quantity", col: "Experience", value: score[6] },
      { row: "Relevance", col: "Qualification", value: score[4] },
      { row: "Relevance", col: "Skills", value: score[1] },
      { row: "Relevance", col: "Experience", value: score[7] },
      { row: "Quality", col: "Qualification", value: score[5] },
      { row: "Quality", col: "Skills", value: score[2] },
      { row: "Quality", col: "Experience", value: score[8] },
    ];

    d3.select(container).select("#heatmap").html("");
    d3.select(container).select("#legend").html("");

    const margin = { top: 30, right: 30, bottom: 70, left: 100 },
      width = 600 - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;

    const svg = d3
      .select(container)
      .select("#heatmap")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const xLabels = Array.from(new Set(data.map((d) => d.col)));
    const yLabels = Array.from(new Set(data.map((d) => d.row)));

    const xScale = d3
      .scaleBand()
      .domain(xLabels)
      .range([0, width])
      .padding(0.1);

    const yScale = d3
      .scaleBand()
      .domain(yLabels)
      .range([height, 0])
      .padding(0.1);

    const colorScaleRed = d3
      .scaleLinear()
      .domain([40, 0])
      .range(["#ffE6E6", "#ff3333"]);

    const colorScaleGreen = d3
      .scaleLinear()
      .domain([40, 100])
      .range(["#E6FFE6", "#339933"]);

    const tooltip = d3
      .select(container)
      .select(".heat")
      .append("div")
      .attr("class", "tooltip")
      .style("display", "none");

    svg
      .selectAll()
      .data(data)
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(d.col))
      .attr("y", (d) => yScale(d.row))
      .attr("width", xScale.bandwidth())
      .attr("height", yScale.bandwidth())
      .attr("class", "cell")
      .style("fill", (d) =>
        d.value < 40 ? colorScaleRed(d.value) : colorScaleGreen(d.value)
      )
      .on("mouseover", function (event, d) {
        d3.select(this).style("stroke", "white").style("stroke-width", "5");

        // Highlight the corresponding row and column labels
        d3.select(container)
          .selectAll(".x-axis .tick text")
          .filter(function (label) {
            return label === d.col;
          })
          .style("font-weight", "bold")
          .style("fill", "#F4BF43");

        d3.select(container)
          .selectAll(".y-axis .tick text")
          .filter(function (label) {
            return label === d.row;
          })
          .style("font-weight", "bold")
          .style("fill", "#F4BF43");

        const cellPosition = this.getBoundingClientRect();
        const tooltipHeight = 35; // Adjust if needed

        tooltip.transition().duration(50).style("opacity", 0.9);

        tooltip
          .html(`<strong>SCORE: ${d.value}</strong>`)
          .style(
            "left",
            cellPosition.left +
              window.scrollX +
              cellPosition.width / 2 -
              50 +
              "px"
          )
          .style(
            "top",
            cellPosition.top + window.scrollY - tooltipHeight + 63 + "px"
          )
          .style("position", "absolute")
          .style("text-align", "center")
          .style("width", "100px")
          .style("margin-inline", "auto")
          .style("height", "25px")
          .style("padding", "5px")
          .style("font", "14px sans-serif")
          .style("background", "lightsteelblue")
          .style("color", "white")
          .style("border", "0px")
          .style("border-radius", "8px")
          .style("pointer-events", "none")
          .style("transition", "opacity 0.1s ease")
          .style("white-space", "nowrap") // Prevent text wrap
          .style("display", "block")
          .style("box-shadow", "0px 0px 10px rgba(0, 0, 0, 0.5)");

        arrow
          .attr(
            "transform",
            `translate(${legendWidth}, ${legendScale(d.value)})`
          )
          .style("opacity", 1);
      })
      .on("mouseout", function (event, d) {
        d3.select(this).style("stroke-width", 2);

        d3.select(container)
          .selectAll(".x-axis .tick text")
          .filter(function (label) {
            return label === d.col;
          })
          .style("font-weight", null)
          .style("fill", null);

        d3.select(container)
          .selectAll(".y-axis .tick text")
          .filter(function (label) {
            return label === d.row;
          })
          .style("font-weight", null)
          .style("fill", null);

        tooltip.transition().duration(50).style("opacity", 0);
        arrow.style("opacity", 0);
      });

    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(xScale).tickSize(0).tickPadding(10))
      .selectAll("text")
      .attr("dy", "1em")
      .attr("class", "axis-label")
      .style("font-size", "15px");

    svg
      .append("g")
      .attr("class", "y-axis")
      .call(d3.axisLeft(yScale).tickSize(0).tickPadding(10))
      .selectAll("text")
      .attr("dx", "-1em")
      .attr("class", "axis-label")
      .style("font-size", "15px");

    // Hide axis lines
    svg.selectAll(".domain").attr("stroke", "none");

    const legendHeight = 210,
      legendWidth = 20;

    const legendSvg = d3
      .select(container)
      .select("#legend")
      .append("svg")
      .attr("width", legendWidth + 50)
      .attr("height", legendHeight + 50)
      .append("g")
      .attr("transform", "translate(20, 20)");

    const legendScale = d3
      .scaleLinear()
      .domain([0, 100])
      .range([legendHeight, 0]);

    const legendAxis = d3.axisRight(legendScale).ticks(5);

    const gradient = legendSvg
      .append("defs")
      .append("linearGradient")
      .attr("id", "legend-gradient")
      .attr("x1", "0%")
      .attr("y1", "100%")
      .attr("x2", "0%")
      .attr("y2", "0%");

    gradient.append("stop").attr("offset", "0%").attr("stop-color", "#ff0000");
    gradient.append("stop").attr("offset", "30%").attr("stop-color", "#ffcccc");
    gradient.append("stop").attr("offset", "40%").attr("stop-color", "#ccffcc");
    gradient
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "#008000");

    legendSvg
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", legendWidth)
      .attr("height", legendHeight)
      .style("fill", "url(#legend-gradient)");

    legendSvg
      .append("g")
      .attr("transform", `translate(${legendWidth}, 0)`)
      .call(legendAxis)
      .selectAll(".domain")
      .remove();

    legendSvg.selectAll(".tick line").attr("stroke", "none");

    const arrow = legendSvg
      .append("polygon")
      .attr("points", "-10,0 10,0 0,10")
      .attr("fill", "black")
      .style("opacity", 0);
  }

  useEffect(() => {
    generateHeatmap(mapRef.current);
  }, []);

  return (
    <div ref={mapRef}>
      <div className="heat col-span-2 overflow-hidden justify-start mt-5">
        <div className="col-span-2">
          <div className="flex justify-center font-semibold">
            <div className="flex justify-center gap-y-8 mt-8 relative mx-auto w-fit">
              <div
                className={`w-32 rounded-full transition-transform duration-[1500] h-full top-0 absolute left-0 ${
                  mode === 1 && "translate-x-full"
                } bg-green-500 primary-gradient`}
              />
              <button
                onClick={() => setMode(0)}
                className={`${mode && "hover:bg-gray-100 color-black"} ${
                  !mode && "text-white"
                }  px-4 py-2 rounded-full min-w-32 z-[1]`}
              >
                Matrix
              </button>
            </div>
          </div>

          <div className={`${mode === 1 && "hidden"}`}>
            <p className="text-center text-2xl font-bold translate-y-8">
              Evaluation matrix
            </p>

            <div className="overflow-x-auto">
              <div>
                <div id="heatmap-container" className="scale-90">
                  <div id="heatmap"></div>
                  <div id="legend"></div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${mode === 0 && "hidden"}`}>
            <p className="text-center text-2xl font-bold mt-8">Spider map</p>
            <div className=" overflow-x-auto ">
              <div className=" min-w-[530px]">
                <SpiderMap score={score} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Heatmap;
