import React, { useEffect, useState } from "react";
import userData from "../services/userData";
import { Loading } from "./InterviewRoom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { FaCircleCheck } from "react-icons/fa6";

function PaymentSuccess() {
  const [confirmed, setConfirmed] = useState(-1);

  let hasRun = false;

  const [timer, setTimer] = useState(5);

  const startTimer = () => {
    setTimer(5);
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(interval);
          // Callback function at the end of 5 seconds
          window.location.href = "/dashboard";
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    const fetchSubscription = async () => {
      if (hasRun) return;
      hasRun = true;
      try {
        const token = Cookies.get("token");

        const res = await userData.getSubscriptionDetails({ token });
        console.log(res);

        if (res.data?.subscription) {
          toast.success(
            `Plan activated successfully, current plan expires on: ${res.data?.expiry}`
          );
          setConfirmed(1);
          startTimer();
        }
      } catch (err) {
        console.log(err);
        setConfirmed(0);
      }
    };
    fetchSubscription();
  }, []);

  return (
    <div className="h-screen flex items-center w-full justify-center">
      <Card>
        <CardContent>
          {confirmed === 1 && (
            <Typography variant="h5" component="div">
              <p className="flex gap-4 items-center text-center my-4">
                <FaCircleCheck size={40} className="text-green-500" />
                Payment Successful
              </p>
              <div>
                <Typography variant="body2" color="text.secondary">
                  Your subscription has been activated successfully.
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  You can now access all premium features.
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <p className="text-lg mt-5 text-center">
                    Redirecting to dashboard in {timer} seconds....
                  </p>
                </Typography>
              </div>
            </Typography>
          )}
          {confirmed === -1 && (
            <div className="flex flex-col justify-center items-center gap-4">
              <Typography variant="body2" color="text.secondary">
                Awaiting payment confirmation, please do not go back or close
                this window.
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <Loading />
              </Typography>
            </div>
          )}
          {confirmed === 0 && (
            <Typography variant="h5" component="div">
              <p className="flex gap-4 items-center text-center my-4">
                <FaCircleCheck size={40} className="text-red-500" />
                Failed to activate plan
              </p>
              <div>
                <Typography variant="body2" color="text.secondary">
                  We encountered an error while activating your plan.
                </Typography>
              </div>
            </Typography>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default PaymentSuccess;
