import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import loaderJson from "../assets/loader.json";
import { Loading } from "./InterviewRoom";
import { FaCloudUploadAlt } from "react-icons/fa";
import { FileUploader } from "react-drag-drop-files";
import { io } from "socket.io-client";
import { base2 } from "../http_2";
import { FaFilePdf } from "react-icons/fa6";
import Cookies from "js-cookie";
import userData from "../services/userData";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Lottie from "react-lottie-player";

function GetAdvice() {
  const fileTypes = ["PDF"];

  const getSessionID = () => {
    return [...Array(16)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join("");
  };
  const [progress, setProgress] = useState("Uploading");
  const textareaRef = useRef(null);

  const [error, setError] = useState("");
  const [step, setStep] = useState(1);

  const [jobTitle, setJobTitle] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [roleLevel, setRoleLevel] = useState("entry level (0-3 years)");

  const [parsing, setParsing] = useState(false);
  const [sessionID, setSessionID] = useState(getSessionID());
  const [nextText, setNextText] = useState("");
  const [file, setFile] = useState(null);

  const navigate = useNavigate();

  let updateId = 0;

  const [cvUploading, setCVUploading] = useState(false);

  let formData = {};

  const dynamic_id = useSelector((state) => state.GlobalState.dynamic_id);

  const importResume = async () => {
    if (!file) {
      toast.error("No file uploaded!");
      return;
    }
    setCVUploading(true);
    try {
      const form_data = new FormData();
      form_data.append("file", file);
      const res = await userData.importResume(form_data);
      formData = res.data;
      setFile(null);
      setStep(3);
      upload();
      toast.success("CV parsed successfully");
    } catch (err) {
      console.log(err);
      toast.error("Error processing CV");
    } finally {
      setCVUploading(false);
    }
  };

  const getCV = async () => {
    if (!dynamic_id) {
      toast.error(
        "Oops!, something went wrong please refresh the page or login again."
      );
    }
    try {
      console.log("gfg");
      console.log(dynamic_id);
      const res = await userData.getCV(parseInt(dynamic_id));
      console.log("lc");
      const data = res.data;
      formData = {
        name: data.name || "",
        email: data.email || "",
        phone: data.contact_no || "",
        linkedin: data.linkedin_url || "",
        portfolio: data.portfolio || "",
        summary: data.candidate_summary || "",
        skills: data.skills || [],
        location: data.location || "",
        projects: data.projects || [],
        experience: data.experience || [],
        qualifications: data.qualification || [],
        courses_and_certifications: data.courses_and_certifications || "",
        awards_and_achievements: data.awards_and_achievements || "",
        optional: data.other_details || [],
      };
      setStep(3);
      upload();
    } catch (err) {
      console.log("Error while fetching CV", err);
      toast.error("Error while processing CV.");
    }
  };

  const fileChangeHandler = (file) => {
    setError("");
    setFile(file);
  };

  const changeText = (nextData) => {
    setNextText(nextData + ".");
    console.log("Changing text from ", progress, " to ", nextData);
    animateTextChange(nextData + ".");
  };

  const animateTextChange = (nextData) => {
    const textarea = textareaRef.current;
    console.log(textarea);
    if (!textarea) return;
    textarea.classList.add("slide-up");

    setTimeout(() => {
      setProgress(nextData);
      textarea.classList.remove("slide-up");
      textarea.classList.add("slide-in");

      setTimeout(() => {
        textarea.classList.remove("slide-in");
      }, 500);
    }, 500);
  };

  const [loading, setLoading] = useState(false);

  const upload = async () => {
    try {
      setLoading(true);
      setProgress("Uploading...");
      setError("");
      const token = Cookies.get("token");
      const getIdres = await userData.getId(token);
      const id = getIdres.data.id;
      const form_data = new FormData();
      form_data.append("id", id);
      form_data.append("job_role", jobTitle);
      form_data.append("role_level", roleLevel);
      form_data.append("job_description", jobDescription);
      form_data.append("session_id", sessionID);
      form_data.append("cv", JSON.stringify(formData));

      const res_2 = await userData.upload_2(form_data);
      if (
        !res_2.data.score ||
        !res_2.data.gaps ||
        !res_2.data.overall_score ||
        !res_2.data.suggestions
      ) {
        setError("Something went wrong");
        return;
      }
      localStorage.setItem("matrix_score", JSON.stringify(res_2.data.score));
      localStorage.setItem("gaps", JSON.stringify(res_2.data.gaps));
      localStorage.setItem(
        "overall_score",
        JSON.stringify(res_2.data.overall_score)
      );
      localStorage.setItem(
        "suggestions",
        JSON.stringify(res_2.data.suggestions)
      );
      navigate(`/result/evaluate`);
    } catch (err) {
      console.log(err);
      toast.error("Error while getting advice");
      setStep(1);
    }
  };

  useEffect(() => {
    const socket = io(base2, { autoConnect: false });
    socket.connect();

    socket.on("update", async (data) => {
      console.log(data);
      if (!data) return;
      const message = data.message;

      updateId = Math.max(+message[0], updateId);

      if (!Array.isArray(message)) {
        return;
      }

      for (let i = 1; i < message.length; i++) {
        console.log(message, " <--- ", updateId);
        if (updateId == message[0]) changeText(message[i]);
        await new Promise((resolve) => setTimeout(resolve, 3000));
      }
    });

    socket.emit("join_room", { session_id: sessionID });

    socket.on("joined", (data) => {
      console.log("Joined socket: ", data);
      setSessionID(data.sessionID);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className="overflow-hidden">
      <div
        style={{
          transform: `translateX(-${(step - 1) * 100}%)`,
        }}
        className="flex h-full w-full overflow-visible transition-all duration-500"
      >
        {/* Step 1 */}
        <div
          className={`px-5 flex-shrink-0 h-full w-[100%] flex flex-col items-center gap-5 py-8 opacity-0 ${
            step === 1 && "opacity-100"
          }`}
        >
          <div>
            <p className="font-bold text-lg text-center">
              PARINAAM Advisory (Beta version)
            </p>
            <p className="text-secondary-text font-semibold text-sm text-center">
              You can optionally provide job details for more specific advice.
            </p>
          </div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              width: "100%",
              maxWidth: 600,
              margin: "auto",
              mt: 4,
              p: 3,
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              borderRadius: 2,
              backgroundColor: "white",
            }}
          >
            <TextField
              label="Job Title (optional)"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              variant="outlined"
              fullWidth
            />

            <FormControl fullWidth>
              <InputLabel id="select-label">Role level</InputLabel>
              <Select
                labelId="select-label"
                value={roleLevel}
                label="Role level"
                onChange={(e) => setRoleLevel(e.target.value)}
              >
                <MenuItem value={"entry level (0-3 years)"}>
                  Entry level
                </MenuItem>
                <MenuItem value={"mid-senior level (3-10 years)"}>
                  Mid-senior level
                </MenuItem>
                <MenuItem value={"senior level (>10 years)"}>
                  Senior level
                </MenuItem>
              </Select>
            </FormControl>

            <TextareaAutosize
              aria-label="Job description (optional)"
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
              placeholder="Job description (optional)"
              minRows={7}
              maxRows={10}
              className=" resize-none px-3 py-2 border bg-yellow-50"
            />
            <Button
              variant={"outlined"}
              onClick={() => {
                setStep(2);
              }}
            >
              Next
            </Button>
          </Box>
        </div>

        {/* Step 2 */}
        <div
          className={`w-full flex-shrink-0 opacity-0 ${
            step === 2 && "opacity-100"
          } h-full transition duration-300 flex flex-col items-center gap-5 py-12`}
        >
          <div>
            <p className="text-lg font-bold text-center">Upload a CV</p>
            <p className="text-secondary-text font-semibold text-sm text-center">
              PARINAAM uses your CV to evaluate your professional background and
              generate personalized advice for you.
            </p>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                width: "100%",
                maxWidth: 600,
                margin: "auto",
                mt: 4,
                p: 3,
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: 2,
                backgroundColor: "white",
              }}
            >
              <div className="flex flex-col gap-4">
                <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                  <Box
                    sx={{
                      mt: 1,
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      borderRadius: "4px",
                      padding: "10px",
                      backgroundColor: "rgb(250, 250, 250)",
                    }}
                  >
                    <div className="mb-5">
                      <FileUploader
                        handleChange={fileChangeHandler}
                        onSizeError={() =>
                          setError("File size should not exceed 10MB! ")
                        }
                        name="file"
                        types={fileTypes}
                        maxSize={10}
                        className={"scale-50"}
                      />
                      <p className="flex items-center gap-2 my-2 ml-2">
                        {file && <FaFilePdf size={20} />}
                        {file
                          ? `File name: ${file?.name}`
                          : "No files uploaded yet"}
                      </p>
                    </div>
                  </Box>
                </FormControl>
                {!cvUploading && (
                  <button
                    onClick={importResume}
                    className={` bg-secondary ${
                      !cvUploading && !parsing && "hover:bg-secondary/70"
                    } ${
                      (cvUploading || parsing) && "bg-gray-500"
                    } px-3 py-2 rounded-md text-white flex items-center gap-2 justify-center`}
                  >
                    <p>UPLOAD</p>
                    <FaCloudUploadAlt />
                  </button>
                )}
                {cvUploading && (
                  <div className="flex gap-2 items-center justify-center">
                    <Loading />
                    <p>Parsing resume</p>
                  </div>
                )}
                <div className="flex justify-between items-center">
                  <div className="flex-grow">
                    <Divider />
                  </div>
                  <p className="mx-2 text-secondary-text">OR</p>
                  <div className="flex-grow">
                    <Divider />
                  </div>
                </div>
                {!parsing && (
                  <Button
                    disabled={cvUploading || parsing}
                    onClick={getCV}
                    variant="contained"
                    color={"success"}
                  >
                    Use from "My CV" area
                  </Button>
                )}
                {parsing && (
                  <div className="flex gap-2 items-center justify-center">
                    <Loading />
                    <p className="text-sm font-semibold text-secondary">
                      Digesting your CV
                    </p>
                  </div>
                )}
              </div>
            </Box>
          </div>
        </div>

        {/* Step 3 */}
        <div
          className={`w-full flex-shrink-0 opacity-0 ${
            step === 3 && "opacity-100"
          } h-full transition duration-300 flex flex-col items-center gap-5 py-12`}
        >
          <div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                width: "100%",
                maxWidth: 600,
                minWidth: 600,
                margin: "auto",
                mt: 4,
                p: 3,
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: 2,
                backgroundColor: "white",
              }}
            >
              {loading && (
                <div className="flex flex-col gap-3 py-4 w-full">
                  <div className="flex justify-center w-full overflow-visible">
                    <Lottie
                      loop
                      animationData={loaderJson}
                      play
                      style={{ width: 150, height: 150 }}
                    />
                  </div>
                  <p
                    ref={textareaRef}
                    className=" text-center my-20 transition-all duration-[500] ease-in-out text-primary-bold font-semibold text-lg"
                  >
                    {progress}
                  </p>
                </div>
              )}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetAdvice;
