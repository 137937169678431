import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Navbar from "./components/Navbar";
import Sidenav from "./components/Sidenav";
import UserHome from "./pages/UserHome";
import InterviewRoom, { Loading } from "./pages/InterviewRoom";
import FileUpload from "./pages/FileUpload";
import ResultEvaluate from "./pages/ResultEvaluate";
import ResultImprove from "./pages/ResultImprove";
import DownloadCVDialog from "./components/DownloadCVDialog";
import EditProfile from "./components/EditProfile";
import Feedback from "./pages/Feedback";
import EvaluateFeedback from "./components/FeedbackComponents/EvaluateFeedback";
import ImproveFeedback from "./components/FeedbackComponents/ImproveFeedback";
import InterviewFeedback from "./components/FeedbackComponents/InterviewFeedback";
import BuildFeedback from "./components/FeedbackComponents/BuildFeedback";
import OverallFeedback from "./components/FeedbackComponents/OverallFeedback";
import FirstProfile from "./components/FirstProfile";
import AdminPortal from "./components/AdminPortalComponents/AdminPortal";
import ChangePassword from "./pages/ChangePassword";
import { useEffect, useState } from "react";
import EvaluationResult from "./pages/evaluationResult";
import UpdatedMyCV from "./pages/UpdatesMyCV";
import CoverLetter from "./pages/CoverLetter";
import InterviewRoomUpdated from "./pages/InterviewRoomUpdated";
import ColdEmail from "./pages/ColdEmail";
import GetAdvice from "./pages/GetAdvice";
import CentralPage from "./pages/CentralPage";
import { toast } from "react-toastify";
import userData from "./services/userData";
import Cookies from "js-cookie";
import Pricing from "./pages/Pricing";
import PaymentCallback from "./pages/PaymentCallback";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentFailure from "./pages/PaymentFailure";

function App() {
  const [verifying, setVerifying] = useState(false);

  const imageUrls = [
    `${process.env.PUBLIC_URL}/template_0.png`,
    `${process.env.PUBLIC_URL}/template_1.png`,
    `${process.env.PUBLIC_URL}/template_2.png`,
    `${process.env.PUBLIC_URL}/doyouhvcv.gif`,
    `${process.env.PUBLIC_URL}/CV.png`,
    `${process.env.PUBLIC_URL}/Step3.jpg`,
  ];

  const preloadImages = (urls) => {
    urls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  };

  useEffect(() => {
    preloadImages(imageUrls);
  }, []);

  const location = useLocation();

  const navigate = useNavigate();

  const noNavPaths = [
    "/",
    "/login",
    "/register",
    "/profile",
    "/change-password",
    "/admin-portal",
    "/payment-success",
    "/payment-failure",
  ];

  const noSideNavPaths = [
    "/",
    "/login",
    "/register",
    "/profile",
    "/change-password",
    "/dashboard",
    "/pricing",
    "/admin-portal",
    "/payment-success",
    "/payment-failure",
  ];

  const allowedUrlsWithoutSubscription = [
    "/",
    "/login",
    "/register",
    "/profile",
    "/change-password",
    "/pricing",
    "/admin-portal",
    "/payment-success",
    "/payment-failure",
  ];

  const verifySubscription = async () => {
    try {
      setVerifying(true);
      const token = Cookies.get("token");
      if (!token) {
        toast.warn("Session expired, please login again");
        navigate("/");
      }
      const data = { token };
      const res = await userData.getSubscriptionDetails(data);
      console.log(res);
      if (!res.data?.subscription) {
        navigate("/pricing");
        toast.error("You do not have an active plan currently.");
      }
    } catch (err) {
      console.log(err);
      navigate("/");
      // toast.warn("Oops, we encountered an error");
    } finally {
      setVerifying(false);
    }
  };

  useEffect(() => {
    if (!allowedUrlsWithoutSubscription.includes(location.pathname)) {
      verifySubscription();
    }
  }, [location.pathname]);

  return (
    <div className="bg-white">
      <DownloadCVDialog />
      <EvaluateFeedback />
      <InterviewFeedback />
      <BuildFeedback />
      <ImproveFeedback />
      <OverallFeedback />
      <div className="min-h-[100vh] max-w-[100vw] overflow-x-hidden">
        <div className="grid grid-cols-5 gap-5 min-h-screen px-1 sm:px-4 nmd:px-8 py-5">
          {!noSideNavPaths.includes(location.pathname) && (
            <div className="z-[100]">
              <Sidenav />
            </div>
          )}
          <div
            className={`${
              noSideNavPaths.includes(location.pathname)
                ? "col-span-5"
                : "col-span-4"
            } cv-scroll-container`}
          >
            {!noNavPaths.includes(location.pathname) && <Navbar />}
            {verifying ? (
              <div className="h-full flex justify-center items-center">
                <Loading />
              </div>
            ) : (
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Signup />} />
                <Route
                  path="/interview-room-updated"
                  element={<InterviewRoom />}
                />
                <Route path="/user-home/:id" element={<UserHome />} />
                <Route path="/edit-profile" element={<EditProfile />} />
                <Route
                  path="/file-upload/:service/:method"
                  element={<FileUpload />}
                />
                <Route path="/result/evaluate" Component={EvaluationResult} />
                <Route path="/testing" Component={ResultEvaluate} />
                <Route path="/result/improve" element={<ResultImprove />} />
                <Route path="/feedback" element={<Feedback />} />
                <Route path="/profile" element={<FirstProfile />} />
                <Route path="/admin-portal" element={<AdminPortal />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/my-cv" Component={UpdatedMyCV} />
                <Route path="/cover-letter" Component={CoverLetter} />
                <Route
                  path="/interview-room"
                  Component={InterviewRoomUpdated}
                />
                <Route path="/cold-email" Component={ColdEmail} />
                <Route path="/get-advice" Component={GetAdvice} />
                <Route path="/dashboard" Component={CentralPage} />
                <Route path="/pricing" Component={Pricing} />
                <Route path="/payment-success" Component={PaymentSuccess} />
                <Route path="/payment-failure" Component={PaymentFailure} />
              </Routes>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
