import http from "../http_1";
import http_2 from "../http_2";

class userData {
  sendOTP(data) {
    return http.post("/otp", data);
  }

  verifyOtp(data) {
    return http.post("/verify-otp", data);
  }

  signup(data) {
    return http.post("/signup", data);
  }

  login(data) {
    return http.post("/login", data);
  }

  profile(data) {
    return http.post("/profile", data);
  }

  changePassword(data) {
    return http.post("/change-password", data);
  }

  checkProfile(token) {
    return http.get(`/checkProfile/${token}`);
  }

  upload(data) {
    return http_2.post("/upload", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  improve(data) {
    return http_2.post("/improve", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  upload_2(data) {
    return http_2.post("/upload_2", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getCV(data) {
    console.log("P->data = ", data);
    return http.get(`/getCV/${data}`);
  }

  getTrialsRemaining(token) {
    return http.get(`/get-trials-remaining/${token}`);
  }

  getId(token) {
    return http.get(`/getId/${token}`);
  }

  idCheck(data) {
    return http.post("/idCheck", data);
  }

  addActivity(data) {
    return http.post("/addActivity", data);
  }
  getActivity(token) {
    return http.get(`/getActivity/${token}`);
  }

  getProfileData(token) {
    return http.get(`/getProfileData/${token}`);
  }

  editProfile(data) {
    return http.put("/editProfile", data);
  }

  checkUserExists(email) {
    return http.get(`/user-exists/${email}`);
  }

  generateCaptcha() {
    return http_2.get("/generateCaptcha", {
      responseType: "blob",
    });
  }

  downloadCV(data) {
    console.log("Download cv data = ", data);
    return http_2.post(`/download_pdf`, data, {
      responseType: "blob",
    });
  }

  resendOtp(data) {
    return http.post("/resendOtp", data);
  }

  getCaptcha() {
    return http.get("/getCaptcha", { withCredentials: true });
  }
  verifyCaptcha(data) {
    return http.post("/verifyCaptcha", data, { withCredentials: true });
  }
  checkLastData(token) {
    return http.get(`/checkLastData/${token}`);
  }
  enableBuild(token) {
    return http.get(`/enableBuild/${token}`);
  }
  getNewField(token) {
    return http.get(`/getNewField/${token}`);
  }

  verifyToken(token) {
    return http.get(`/verify-token/${token}`);
  }

  getEvaluationReport(data) {
    return http_2.post(`/get-evaluation-report`, data, {
      responseType: "blob",
    });
  }

  getImprovementReport(data) {
    return http_2.post(`/get-improvement-report`, data, {
      responseType: "blob",
    });
  }

  changeAvatar(data, token) {
    return http_2.post(`/change-avatar/${token}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getAvatar(token) {
    return http.get(`/get-avatar/${token}`);
  }

  requestAccess(email, password) {
    return http.post("/request-access", {
      email: email,
      password: password,
    });
  }

  grantAccess(email) {
    return http.post("/grant-access", {
      email: email,
    });
  }

  getPendingUsers() {
    return http.get("/get-pending-users");
  }

  verifyAdmin(token) {
    return http.post("/verify-admin", {
      token,
    });
  }

  getAllUsers() {
    return http.get("/get-all-users");
  }

  evaluateInterview(formData) {
    return http_2.post("/evaluate", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  appuAudioCreate(data) {
    return http_2.post("/api/translate_and_speak", data);
  }

  appuAudioFetch(id) {
    return http_2.get(`/get-appu-audio/${id}`, {
      responseType: "blob",
    });
  }

  updateTrials(data) {
    return http.post("/update-trials", data);
  }

  summarizeText(text) {
    return http_2.post("/evaluate-summary", {
      evaluation_text: text,
    });
  }

  checkFeedbackStatus(data) {
    return http.post("/get-feedback-status", data);
  }

  storeFeedback(service, data) {
    return http.post(`/store-${service}-feedback`, data);
  }

  getAllFeedback() {
    return http.get("/get-feedback");
  }

  selectVersion(data) {
    return http.post("/select-prompts", data);
  }

  downloadInterviewReport(data) {
    return http_2.post(`/get-interview-report`, data, {
      responseType: "blob",
    });
  }

  grantBulk(data) {
    return http.post("/grant-bulk", data);
  }

  getTechnicalQuestions(data) {
    return http_2.post("/get-technical-questions", data);
  }

  importResume(data) {
    return http_2.post("/import-resume", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  digestCV(data) {
    return http_2.post("/digest-cv", data);
  }

  generateCoverLetter(data) {
    return http_2.post("/generate-cover-letter", data);
  }

  generateColdEmail(data) {
    return http_2.post("/generate-cold-email", data);
  }

  htmlToPdfService(data) {
    return http_2.post(`/html-to-pdf-service`, data, {
      responseType: "blob",
    });
  }

  getSubscriptionDetails(data) {
    return http.post("/get-subscription-details", data);
  }

  verifyReferralCode(data) {
    return http.post("/verify-referral-code", data);
  }

  useReferralCode(data) {
    return http.post("/use-referral-code", data);
  }

  generateReferralCodes(data) {
    return http.post("/create-referral-code", data);
  }

  fetchPlans(data) {
    return http.post("/fetch-plans", data);
  }

  updatePlan(data) {
    return http.post("/update-plans", data);
  }

  getAllPlans() {
    return http.get("/get-all-plans");
  }

  deletePlan(data) {
    return http.post("/delete-plan", data);
  }

  generateTrialCodes(data) {
    return http.post("/generate-trial-codes", data);
  }

  buySubscription(data) {
    return http.post("/buy-subscription", data);
  }

  adminFilter(data) {
    return http.post("/admin-filter", data);
  }

  manualAdjustPlan(data) {
    return http.post("/manual-adjust-plan", data);
  }
}

export default new userData();
