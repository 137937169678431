import React, { useEffect, useRef, useState } from "react";
import { PiReadCvLogo } from "react-icons/pi";
import { BsFillPeopleFill } from "react-icons/bs";
import { IoDocumentAttachSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaCheckDouble } from "react-icons/fa6";
import { VscFeedback } from "react-icons/vsc";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaRegEdit, FaRegUserCircle } from "react-icons/fa";
import { VscLayoutActivitybarLeft } from "react-icons/vsc";
import Cookies from "js-cookie";
import { Loading } from "./InterviewRoom";
import userData from "../services/userData";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const formatDate = (dateString) => {
  const options = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  return new Date(dateString).toLocaleString("en-GB", options);
};

function Card({ label, Icon, link }) {
  return (
    <NavLink
      to={link}
      className={`bg-secondary text-white rounded-lg p-4 
        hover:-translate-y-1
       transition-all duration-500`}
    >
      <div>
        <p className="text-xl font-bold">{label}</p>
        <div className="flex justify-end mt-4">
          <Icon size={30} />
        </div>
      </div>
    </NavLink>
  );
}

function CentralPage() {
  const location = useLocation();

  let hasRun = false;
  useEffect(() => {
    if (!hasRun) {
      const queryParams = new URLSearchParams(location.search);

      const productId = queryParams.get("products[1][id]");
      const useTestMode = queryParams.get("use-test-mode");

      if (productId || useTestMode) {
        toast.success("Subscription activated");
      }
      console.log("Product ID:", productId);
      console.log("Use Test Mode:", useTestMode);

      hasRun = true;
    }
  }, [location.search]);

  const data = [
    {
      label: "My CV",
      icon: PiReadCvLogo,
      link: "/my-cv",
    },
    {
      label: "Give feedback",
      icon: VscFeedback,
      link: "/feedback",
    },

    {
      label: "Cover letter",
      icon: IoDocumentAttachSharp,
      link: "/cover-letter",
    },
    {
      label: "Cold emailing",
      icon: MdEmail,
      link: "/cold-email",
    },
    {
      label: "Get advice",
      icon: FaCheckDouble,
      link: "/get-advice",
    },
    {
      label: "Interview room",
      icon: BsFillPeopleFill,
      link: "/interview-room",
    },
  ];

  const [open, setOpen] = useState(false);

  const ref = useRef(null);

  const name = useSelector((state) => state.GlobalState.userDetails.name);

  useEffect(() => {
    function clickHandler(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    }
    window.addEventListener("click", clickHandler);

    return () => {
      window.removeEventListener("click", clickHandler);
    };
  }, []);

  const [activities, setActivities] = useState(null);

  const getActivities = async () => {
    try {
      const token = Cookies.get("token");
      const res = await userData.getActivity(token);
      const arr = res.data.data;
      arr.sort((a, b) => new Date(b.createdat) - new Date(a.createdat));
      setActivities(arr);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const mp = {
    improve: "Improved CV",
    interview: "Used interview room",
    letter: "Generated cover letter",
    email: "Generated cold email",
    evaluate: "Get advice",
    login: "Logged in",
  };

  const formatActivity = (activity) => {
    return mp[activity] || "";
  };

  return (
    <div className="w-screen flex flex-col items-center gap-4 justify-center">
      <div>
        <div className="bg-secondary relative text-white rounded-lg p-4 my-4 min-h-[200px]">
          <div>
            <p className="text-xl font-bold">{`Hi, ${name}`}</p>
            <NavLink
              to="/edit-profile"
              className="flex gap-2 items-center z-10"
            >
              <p>Profile</p>
              <FaRegEdit />
            </NavLink>
          </div>
          <div className="absolute bg-gray-300 flex primary-shadow justify-center w-fit left-[50%] -translate-x-[50%] rounded-full my-auto ">
            <FaRegUserCircle size={190} />
          </div>
          <div className="absolute right-4 bottom-4">
            <button
              onClick={(e) => {
                e.stopPropagation();
                getActivities();
                setOpen(true);
              }}
              className="flex gap-2 items-center bg-white text-secondary hover:bg-gray-100 px-3 py-2 rounded-md transition-all duration-300"
            >
              <VscLayoutActivitybarLeft /> <p>Show activities</p>
            </button>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2">
          {data.map((obj, index) => {
            const { label, icon, link } = obj;
            return <Card label={label} Icon={icon} link={link} />;
          })}
        </div>
      </div>
      <div
        ref={ref}
        className={`fixed w-[500px] top-[5vh] h-[90vh] rounded-l-xl bg-white -right-[500px] ${
          open && "-translate-x-[100%]"
        } transition-all duration-500 flex justify-center p-4 overflow-y-auto`}
      >
        {!activities && (
          <div className="flex w-full items-center justify-center">
            <Loading />
          </div>
        )}
        {activities && (
          <div className="p-5 overflow-auto w-full">
            <table className="w-full">
              <thead>
                <th>Date</th>
                <th>Activity log</th>
              </thead>
              <tbody>
                {activities.map((activity, index) => {
                  return (
                    <tr className="py-2 border-b border-t" key={index}>
                      <td className="">
                        <p className="pl-4 px-5 text-xs bg-secondary text-white rounded-full py-1 text-center">
                          {formatDate(activity.createdat)}
                        </p>
                      </td>
                      <td className=" px-5 text-gray-600 text-sm text-center">
                        {activity.activity
                          ? formatActivity(activity.activity)
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default CentralPage;
