import React from 'react'

function PaymentFailure() {
  return (
    <div>
        <p>Payment failed</p>
    </div>
  )
}

export default PaymentFailure
